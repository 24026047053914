import React, { useEffect } from 'react';
import i18next from 'i18next';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import { Helmet } from 'react-helmet';
import resources from './locales/index.js';
import App from './ui/App';
import { ThemeContainer } from './_modules';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

Sentry.init({
  dsn: 'http://8581d86994b245ea343f43f406234f15@sentry.dtechs.dev/17',

  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],

  tracesSampleRate: 1.0,

  tracePropagationTargets: ['localhost'],

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const init = async () => {
  // @ts-ignore
  const i18n = i18next.createInstance();
  const userLanguage = navigator.language === 'ru' ? 'ru' : 'en';
  await i18n.use(initReactI18next).init({ resources, fallbackLng: userLanguage });

  return (
    <I18nextProvider i18n={i18n}>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <link rel="icon" type="image/x-icon" href="assets/icons/logo.png" />
      </Helmet>
      <ThemeContainer>
        <App />
      </ThemeContainer>
    </I18nextProvider>
  );
};

export default init;
